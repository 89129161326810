/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.lot-not-valid {
  font-size: 2em;
  color: #f5222d;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin: 24px auto;
  padding: 16px;
  text-align: center;
  max-width: 500px;
}
.lot-not-valid .description {
  font-size: 0.5em;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 24px;
}
